code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import "sass/_variables";

html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0 auto;
  background-image: url("img/global-bg.jpg");
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

.container {
  height: auto;
}
