@import '../../sass/_variables';

.certificacions {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../img/block-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: inherit;
  &-bg {
    width: 100%;
    height: 37%;
    background-image: url("../../img/menu-code-reverse.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: inherit;
    position: relative;
    &-plant {
      width: 180px;
      position: absolute;
      left: 34vw;
      top: 0;
      @media (max-width: $xl) {
        display: none;
      }
    }
  }

  &-pics {
    position: relative;
    width: 100%;
    height: 470px;
    &-title {
      @include RobotoMono-light;
      font-size: 24px;
      line-height: 1;
      color: #fff;
      margin: 200px 0 0 180px;
      @media (max-width: $xl) {
        margin: 120px 0 0 165px;
      }
      @media (max-width: $large) {
      }
      @media (max-width: $middle) {
        margin: 30px;
        text-align: center;
      }
    }

    &-js {
      width: 400px;
      position: absolute;
      left: 57vw;
      top: 17vw;
      z-index: 1;
      @media (max-width: $xl) {
        left: 62vw;
      }
      @media (max-width: $large) {
        left: 57vw;
        top: 41vw;
      }
      @media (max-width: $middle) {
        left: 22vw;
        top: 44vw;
      }
      @media (max-width: $m-s) {
        left: 16vw;
        top: 46vw;
      }
      @media (max-width: $small) {
        left: 14vw;
        top: 62vw;
        width: 330px;
      }
      @media (max-width: $xs) {
        left: 18vw;
        top: 72vw;
        width: 280px;
      }
      @media (max-width: $xxs) {
        left: 13vw;
        top: 72vw;
      }
    }
    &-plant {
      width: 180px;
      position: absolute;
      left: 34vw;
      z-index: 1;
      top: 25vw;
      @media (max-width: $xl) {
        display: none;
      }
    }
    &-web {
      width: 260px;
      position: absolute;
      left: 17vw;
      top: 20vw;
      z-index: 1;
      @media (max-width: $xl) {
        left: 14vw;
      }
      @media (max-width: $large) {
        left: 5vw;
        top: 46vw;
      }
      @media (max-width: $middle) {
        left: 32vw;
        top: 12vw;
      }
      @media (max-width: $m-s) {
        left: 28vw;
        top: 12vw;
      }
      @media (max-width: $small) {
        left: 26vw;
        top: 26vw;
        width: 220px;
      }
      @media (max-width: $xs) {
        left: 28vw;
        top: 30vw;
        width: 200px;
      }
      @media (max-width: $xxs) {
        left: 24vw;
        top: 28vw;
      }
    }
    &-react {
      width: 470px;
      position: absolute;
      left: 32vw;
      top: 5vw;
      @media (max-width: $large) {
        left: 26vw;
        top: 20vw;
      }
      @media (max-width: $middle) {
        left: 18vw;
        top: 82vw;
      }
      @media (max-width: $m-s) {
        left: 14vw;
        top: 88vw;
        width: 430px;
      }
      @media (max-width: $small) {
        left: 10vw;
        top: 108vw;
        width: 370px;
      }
      @media (max-width: $xs) {
        left: 12vw;
        top: 116vw;
        width: 320px;
      }
      @media (max-width: $xxs) {
        left: 14vw;
        top: 126vw;
        width: 270px;
      }
    }
  }
}
