@import '../../sass/_variables';

.experience {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #27272779;
  height: 460px;
  margin-top: -4px;
  @media (max-width: $middle) {
    flex-direction: column;
    align-items: center;
    height: 660px;
  }

  @media (max-width: $small) {
    height: 830px;
  }

  &-block-title {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: $middle) {
      align-items: center;
    }
    &-name {
      @include RobotoMono-light;
      font-size: 24px;
      line-height: 1;
      color: #fff;
      margin: 200px 0 0 180px;
      @media (max-width: $middle) {
        align-items: center;
        margin: 30px;
      }
    }
  }
  &-block-work {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  &-work {
    @include RobotoMono-medium;
    font-size: 16px;
    text-align: left;
    color: #fff;
    margin: 30px 0;
  }
}
