@import '../../sass/_variables';

.menu {
  margin-top: 80px;
  background-image: url("../../img/menu-bg.png");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: right;
  @media (max-width: $small) {
    padding-top: 80px;
  }
}
.menu-bg {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 510px;
  background-image: url("../../img/menu-code.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: inherit;
  text-align: left;
  @media (max-width: $small) {
    margin-top: 30px;
  }
  &-links {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 998;
    @media (max-width: $small) {
      width: 20%;
    }
    &-img {
      width: 25px;
      padding: 15px;
      cursor: pointer;
      @media (max-width: $small) {
        width: 22px;
      }
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    width: 76%;
    @media (max-width: $small) {
      width: 60%;
      align-items: center;
      margin-top: -30px;
    }
    @media (max-width: $middle) {
      width: 66%;
    }
    &-sname {
      @include RobotoMono-bold;
      font-size: 13vw;
      letter-spacing: 15.5px;
      text-align: left;
      color: #ffffff31;
      margin: 100px 0 0 0;
    }
    &-fname {
      @include RobotoMono-medium;
      width: 163px;
      height: 34px;
      font-size: 36px;
      line-height: 0.67;
      letter-spacing: 7.2px;
      color: #fff;
      margin: -120px 0 0 0;
    }
    &-description-left {
      @include RobotoMono-medium;
      font-size: 18px;
      text-align: left;
      color: #fff;
      margin: 100px 0 0 0;
      @media (max-width: $small) {
        font-size: 15px;
        text-align: center;
      }
    }
    &-description-right {
      @include RobotoMono-medium;
      font-size: 16px;
      text-align: right;
      color: #fff;
      margin: -24px 0 0 0;
    }
  }
  &-lenguages {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: $small) {
      width: 20%;
      font-size: 12px;
    }
    @media (max-width: $middle) {
      width: 20%;
      font-size: 12px;
    }
    &-text {
      @include RobotoMono-medium;
      font-size: 14px;
      line-height: 4;
      text-align: right;
      color: #fff;
      padding: 10px 10px;
      cursor: pointer;
      @media (max-width: $small) {
        font-size: 12px;
      }
    }
  }
}
