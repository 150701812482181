@import '../../sass/_variables';

.contact {
  display: flex;
  flex-direction: row;
  height: 100px;
  margin-top: 14vw;
  align-items: center;
  @media (max-width: $middle) {
    margin-top: 70vw;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &-title {
    width: 50%;
    @media (max-width: $middle) {
      width: 100%;
    }

    &-text {
      @include RobotoMono-light;
      font-size: 24px;
      line-height: 1;
      color: #fff;
      margin-left: 180px;
      @media (max-width: $middle) {
        margin-left: 0;
      }
    }
  }
  &-links {
    width: 50%;
    @media (max-width: $middle) {
      width: 100%;
    }
    &-img {
      width: 25px;
      padding: 15px;
      cursor: pointer;
    }
  }
}
