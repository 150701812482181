@import '../../sass/_variables';

.header {
  display: flex;
  flex-direction: row;
  height: 80px;
  color: #fff;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  background-color: #1a191d;
  opacity: 0.9;
  overflow: hidden;
  animation: moveDown 0.5s ease-in-out;
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }
  @media (max-width: $middle) {
  height: 100px;
}
  @media (max-width: $small) {
    height: 140px;
  }



  &-logo {
    width: 14%;
    display: contents;
    &-bg {
      background-color: #c99b89;
      width: 140px;
      height: 80px;
      &-first-splash {
        @include RobotoMono-bold;
        color: #ffffff;
        font-size: 55px;
      }
      &-first-letter {
        @include RobotoMono-bold;
        color: #ffffff;
        font-size: 48px;
      }

      &-second-letter {
        @include RobotoMono-bold;
        color: #1a191d;
        font-size: 48px;
      }
      &-second-splash {
        @include RobotoMono-bold;
        color: #1a191d;
        font-size: 55px;
      }
    }
  }

  &-menu {
    display: flex;
    flex-direction: row;
    width: 76%;
    justify-content: center;
    @media (max-width: $small) {
      flex-direction: column;
      justify-content: center;
    }

  }
  &-menu-section{
    display: flex;
    flex-direction: row;
    @media (max-width: $middle) {
      flex-direction: column;
      justify-content: center;
    }

  }
  &-menu-item {
    margin: 10px 20px;
    cursor: pointer;
    @media (max-width: $m-s) {
      margin: 2px 10px;
    }
  }
}

.menu-btn {
  @include RobotoMono-medium;
  color: #ffffff;
  font-size: 14px;
}
