$xxs: 370px;
$xs: 420px;
$small: 481px;
$m-s: 580px;
$middle: 769px;
$large: 1025px;
$xl: 1201px;
$xxl: 1440px;

@font-face {
  font-family: "RobotoMono";
  font-weight: 700;
  src: url("../fonts/RobotoMono-Bold.ttf");
}
@font-face {
  font-family: "RobotoMono";
  font-weight: 500;
  src: url("../fonts/RobotoMono-Medium.ttf");
}
@font-face {
  font-family: "RobotoMono";
  font-weight: 500;
  src: url("../fonts/RobotoMono-Light.ttf");
}


@mixin RobotoMono-bold {
  font-family: RobotoMono;
  font-weight: 700;
}

@mixin RobotoMono-medium {
  font-family: RobotoMono;
  font-weight: 500;
}
@mixin RobotoMono-light {
  font-family: RobotoMono;
  font-weight: 300;
}
