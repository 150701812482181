@import '../../sass/_variables';
.projects {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: right;
  margin-top: -140px;

  &-head {
    background-image: url("../../img/menu-code-reverse.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: inherit;
    margin-top: 140px;

    &-bg {
      height: 400px;
      background-image: url("../../img/projects-bg.png");
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: inherit;
      @media (max-width: $middle) {
        height: 270px;
        background-size: 110%;
      }
    }
    &-title {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: $middle) {
        justify-content: center;
        width: 100%;
      }
      &-text {
        @include RobotoMono-light;
        font-size: 24px;
        line-height: 1;
        color: #fff;
        margin: 200px 0 0 180px;
        @media (max-width: $middle) {
          text-align: center;
          margin: 100px 0 0 0px;
        }
      }
    }
  }
}

.block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-image: url("../../img/block-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;

  &-project {
    display: flex;
    flex-direction: column;
    text-align: center;
    &-text {
      @include RobotoMono-light;
      font-size: 14px;
      line-height: 1.71;
      color: #fff;
    }
  }
}

//My card code
.card-container {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: #1f1d1cc9;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 24px;
  filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 450px;
  margin: 25px 25px;
  @media (max-width: $middle) {
    width: 350px;
    height: 450px;
  }
  @media (max-width: $small) {
    width: 280px;
    height: 450px;
  }
}

.card-wrapper {
  width: 100%;
  height: 100%;
}

.card-banner-image {
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.255);
  filter: brightness(70%);
  &:hover {
    filter: brightness(100%);
  }
}

.card-button-wrapper {
  margin-top: 10px;
}

.card-btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;
  letter-spacing: 2px;
  cursor: pointer;
}

.card-btn + .card-btn {
  margin-left: 20px;
}

.card-outline {
  background: transparent;
  color: #c99b89;
  border: 1px solid #c99b89;
  transition: all 0.3s ease;
}

.card-outline:hover {
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.card-fill {
  background: #c99b89;
  color: rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all 0.3s ease;
}

.card-fill:hover {
  transform: scale(1.125);
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
  transition: all 0.3s ease;
}

.react-img {
  background-image: url("../../img/react-example-page.solomka.dev_.png");
}
.store-img {
  background-image: url("../../img/webstore-project.solomka.dev_.png");
}
.figma-img {
  background-image: url("../../img/figma-project.solomka.dev_.png");
}
.booking-img {
  background-image: url("../../img/restaurant-bookings.solomka.dev_.png");
}
.cash-img {
  background-image: url("../../img/cash-counter.png");
}
