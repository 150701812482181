@import '../../sass/_variables';

.footer {
  display: flex;
  width: 100%;
  height: 140px;
  background-color: rgba(39, 39, 39, 0.4745098039);
  justify-content: center;
  align-items: center;
}
.footer-text {
  @include RobotoMono-medium;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-top: 36px;
  cursor: pointer;
}
